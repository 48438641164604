<template>
    <div>
        <inner-banner heading="" banner-type="about" content="">
            <template #content>
                <div class="bottom-tag">
                    <h6 class="font-inter-medium">Seize Opportunities, Secure Deals: Master the Art of E Auctions with Us.</h6>
                </div>
            </template>
        </inner-banner>

        <section id="upcoming-auctions" class="pt-6">
            <upcoming-auctions></upcoming-auctions>
        </section>
        <section id="featured-banks" v-if="details.featured_banks.length!==0" class="py-4 py-lg-5 bg-4">
                <featured-banks :details="details.featured_banks"></featured-banks>
        </section>
<!--        <section id="featured-banks" class="py-6">-->
<!--            <featured-banks></featured-banks>-->
<!--        </section>-->

        <section id="sub-footer">
            <sub-footer></sub-footer>
        </section>

    </div>
</template>

<script>
import InnerBanner from '@components/InnerBanner';
import UpcomingAuctions from '../../components/home/sections/UpcomingAuctions';
import FeaturedBanks from '@components/home/sections/FeaturedBanks';
import SubFooter from '@components/home/PremiumMembershipSection';
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name: 'AuctionPage',

    components: { SubFooter, FeaturedBanks, UpcomingAuctions, InnerBanner },
    data () {
        return {
            details: ''
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        async loadDetails () {
            // this.dataLoading = true;
            const response = await axios.get(urls.website.home);
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            // this.dataLoading = false;
            // this.$emit('loadingSuccess');
        }
    }
};
</script>

<style scoped lang="scss">
    .bottom-tag {
        &::after {
            content: '';
            position: absolute;
            margin-top: var(--spacer-2);
            width: 1.4rem;
            transform: translateX(-50%);
            height: 2px;
            //background-color: var(--color-white);
            background-color: var(--bottom-tag-color);
            //margin-top: 1rem;
        }
    }
</style>
